import React from "react";
import { graphql } from "gatsby";
import GenericContentIndexPage from "./generic/genericContentIndexTemplate";
import withLayoutAndData from "./generic/withLayoutAndData";

export const ServicesIndexPage = ({ page }) =>
	<GenericContentIndexPage page={page} showAd />;

export const servicesPageQuery = graphql`
  query ServicesPageQuery($id: String!) {
    markdownRemark(id: {eq: $id }) {
      ...GenericPageFragment
    }
  highlightedEvents: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "event-page"}, featuredpost: {eq: true}}}, limit: 3) {
    edges {
      ...EventHighlight
    }
  }
}`;

export default withLayoutAndData(
	({ data }) => ({
		page: {
			...data.markdownRemark.frontmatter,
			...data.markdownRemark.fields,
			content: data.markdownRemark.html,
		}
	})
)(ServicesIndexPage);

